// src/data/gamesData.js

export const games = [{
    id: "1",
    title: "COLOR MATCH",
    description: "Match Colors, Score Points!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/color_match_logo.png",
    price: "PLAY NOW",
    isPlayable: true,
    link: "https://wowcube.games.telelauncher.com/color-match/",
}, {
    id: "2",
    title: "2048 Classic",
    description: "Match Numbers, Reach 2048!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/2048-classic.png",
    price: "PLAY NOW",
    isPlayable: true,
    link: "https://wowcube.games.telelauncher.com/2048/",
}, {
    id: "3",
    title: "Memory Match",
    description: "Match Emojis, Test Your Memory!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/emoji-hide.png",
    price: "PLAY NOW",
    level: 3,
    isPlayable: true,
    link: "https://wowcube.games.telelauncher.com/memory-match/"
}, {
    title: "Cut the Rope™",
    description: "And Give It to OmNom",
    preview: "https://images.wowcube.com/image/image/project/icon/630f23df71c649e1373a2a14.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/cuttherope",
}, {
    id: "5",
    title: "Bubble Shooter",
    description: "Match All Bubbles!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/bubble-shooter.png",
    price: "PLAY NOW",
    isPlayable: true,
    level: 10,
    link: "https://wowcube.games.telelauncher.com/bubble-shooter/"
}, {
    id: "6",
    title: "Flappy Cube",
    description: "Jump Jump!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/flappy_cube.png",
    price: "PLAY NOW",
    isPlayable: true,
    level: 5,
    link: "https://wowcube.games.telelauncher.com/flappy-cube/"
}, {
    id: "7",
    title: "Shi Cube",
    description: "Check reaction!",
    preview: "https://raw.githubusercontent.com/Say-Apps/wow-cube-public/refs/heads/main/4_squares/cropped_square_image.jpeg",
    price: "PLAY NOW",
    isPlayable: true,
    hidden: true,
    link: "https://wowcube.games.telelauncher.com/shi/"
}, {
    title: "Catch-It",
    description: "Fold the Atoms",
    preview: "https://images.wowcube.com/image/image/project/icon/630f22ea71c649e1373a2361.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/catch-it",
}, {
    title: "SPACE INVADERS™ Cubed",
    description: "Stop the alien invasion!",
    preview: "https://images.wowcube.com/image/image/project/icon/630f2b8771c649e1373a4f24.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/spaceinvaderscubed",
}, {
    title: "2048 CUBED",
    description: "Sum It Up",
    preview: "https://images.wowcube.com/image/image/project/icon/630de2d971c649e13739d5a0.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/2048",
}, {
    title: "Aquarium",
    description: "Diver's Dream",
    preview: "https://images.wowcube.com/image/image/project/icon/630f203d71c649e1373a14e2.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/aquarium",
}, {
    title: "BonBon",
    description: "Your Sweet Dreams",
    preview: "https://images.wowcube.com/image/image/project/icon/630f211871c649e1373a1a01.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/bon-bon",
}, {
    title: "Globe in Cube",
    description: "Grip the Globe - Journey with a Twist",
    preview: "https://images.wowcube.com/wow/project/icon/VbCokKFebIdndGheLwsyq.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/globe-in-cube",
}, {
    title: "Ladybug",
    description: "Fly Away Home",
    preview: "https://images.wowcube.com/image/image/project/icon/630f273a71c649e1373a3a9e.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/ladybug",
}, {
    title: "Snow Globe",
    description: "Your Winter Wonderland",
    preview: "https://images.wowcube.com/wow/project/icon/g6OqQnW5iVgfr1LkRdkCt.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/snow-globe",
}, {
    title: "Steam pipes",
    description: "Let Off The Steam",
    preview: "https://images.wowcube.com/image/image/project/icon/630f2bb671c649e1373a50f8.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/steampipes",
}, {
    title: "Sudoku Cubed",
    description: "One Square at a Time",
    preview: "https://images.wowcube.com/wow/project/icon/THrqPURHWqbQ8kFF1oaMT.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/sudokucubed",
}, {
    title: "Sunny Side Up",
    description: "A game of suns, clouds and flowers!",
    preview: "https://images.wowcube.com/wow/project/icon/mmlyBEeHO6gtRF0jpjjnC.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/sunny-side-up",
}, {
    title: "Twister",
    description: "Twist Your Stress Away!",
    preview: "https://images.wowcube.com/wow/project/icon/y3nuwdTIjgV6gdjIUckeW.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/twister",
}, {
    title: "White Rabbit",
    description: "Late to a Very Important Date",
    preview: "https://images.wowcube.com/image/image/project/icon/630f2c4571c649e1373a59f9.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/white-rabbit",
}, {
    title: "Labyrinth",
    description: "Escape the gravity!",
    preview: "https://images.wowcube.com/image/image/project/icon/630f270f71c649e1373a3875.webp",
    price: "Available on\n WOW Store",
    link: "https://wowcube.com/store/labyrinth",
},];

export const getGameById = (id) => games.find(game => game.id === id);

export const getGameSequence = () => {
    // Фильтруем только игры, доступные для игры
    const playableGames = games.filter(game => game.isPlayable);

    // Сортируем игры по ID
    playableGames.sort((a, b) => {
        // Преобразуем ID в числа для корректного сравнения
        const idA = parseInt(a.id);
        const idB = parseInt(b.id);
        return idA - idB;
    });

    return playableGames;
};